import clsx from "clsx";
import type { ReactNode } from "react";

import styles2 from "./Chat2.module.scss";

//make prop type
type ChatContainerProps = {
	children: ReactNode;
};

export const MessagesContainer = ({ children }: ChatContainerProps) => {
	return (
		<div className={clsx(styles2.messagesWrapper)} data-ui="MessagesContainer">
			<div className={clsx(styles2.messagesContainer)}>{children}</div>
		</div>
	);
};
