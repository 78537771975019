import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CloseIcon } from "../../../assets/icons/Icons";
import { cookieHelper } from "../../../utils/cookieHelper";
import { localStorageHelper } from "../../../utils/localStorageHelper";
import { Button } from "../_Pure/Button/Button";
import styles from "./CookieModal.module.scss";
import { Preferences } from "./Preferences";

export const CookieModal: React.FC = () => {
	const isApprovedFromLS = cookieHelper.isCookiesApproved.get();
	const [isApproved, setIsApproved] = useState(Boolean(isApprovedFromLS));

	const [options, setOptions] = useState({
		necessary: true,
		optional: false,
	});

	const toggleOption = (option: "necessary" | "optional") => {
		setOptions((prev) => ({ ...prev, [option]: !prev[option] }));
	};

	const saveCookiesApproval = () => {
		cookieHelper.isCookiesApproved.set(true);
		setIsApproved(true);
	};

	const [showPreferences, setShowPreferenced] = useState(false);

	const { t } = useTranslation();

	if (isApproved) {
		return null;
	}

	return (
		<div className={styles.container}>
			{showPreferences && (
				<div className={styles.headingContainer}>
					<p className={styles.heading}>{t("cookiesSettings")}</p>
					<button type="button" title={t("close")} className={styles.closeButton} onClick={() => setShowPreferenced(false)}>
						<CloseIcon height={16} />
					</button>
				</div>
			)}
			<p className={styles.content}>
				{t("cookiesContent1")}&nbsp;<a href="http://william.legal/cookies">{t("cookiesContent2")}</a>&nbsp;{t("cookiesContent3")}
			</p>
			{showPreferences && <Preferences options={options} toggleOption={toggleOption} />}
			<div className={styles.buttonsContainer}>
				{showPreferences ? (
					<>
						<Button label={t("saveSettings")} onClick={saveCookiesApproval} disabled={!options.necessary} variant="secondary" />
						<Button label={t("acceptAll")} onClick={saveCookiesApproval} disabled={!options.necessary} variant="cta" />
					</>
				) : (
					<>
						<Button label={t("settings")} onClick={() => setShowPreferenced(true)} disabled={!options.necessary} variant="secondary" />
						<Button label={t("accept")} onClick={saveCookiesApproval} disabled={!options.necessary} variant="cta" />
					</>
				)}
			</div>
		</div>
	);
};
