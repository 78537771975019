import { UserButton, useUser } from "@clerk/clerk-react";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ChatDialogIcon, NewChat } from "../../../assets/icons/Icons";
import { TrialDays } from "../../../constants";
import { useApi } from "../../../hooks/useApi";
import { useChatAccess } from "../../../hooks/useChatAccess";
import paymentStyles from "../../organisms/PaymentInfoModal/PaymentInfoModal.module.scss";
import { Button } from "../_Pure/Button/Button";
import buttonStyles from "../_Pure/Button/Button.module.scss";
import { SectionTitle } from "../_Pure/SectionTitle/SectionTitle";
import { FreeTrialInfo } from "../FreeTrialInfo/FreeTrialInfo";
import { Tooltip, TooltipContentContainer, TooltipTrigger } from "../Tooltip/Tooltip";
import { TooltipContent } from "../Tooltip/TooltipContent/TooltipContent";
import styles from "./ChatTopbar.module.scss";

const DotIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
			<title>{"."}</title>
			<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
		</svg>
	);
};

type SubscriptionButtonProps = {
	label: string;
	onClick?: () => void;
	disable?: boolean;
};

const SubscriptionButton = ({ label, onClick, disable }: SubscriptionButtonProps) => {
	const { t: p } = useTranslation();

	return (
		<button
			className={paymentStyles.ctaButton}
			disabled={disable}
			style={{ margin: 0 }}
			type="button"
			onClick={async () => {
				onClick?.();
			}}
		>
			{p(label)}
		</button>
	);
};

SubscriptionButton.displayName = "SubscriptionButton";

//ItemProps
type ItemProps = {
	condition: boolean;
	text: string | JSX.Element;
	label: string;
	onClick: () => void;
};

const Item = ({ condition, text, label, onClick }: ItemProps) => {
	return (
		<div
			style={{
				border: "1px solid rgba(0, 0, 0, 0.1)",
				padding: "20px 10px",
				borderRadius: 10,
				display: condition ? "flex" : "none",
			}}
		>
			<p
				style={{
					color: "rgba(0, 0, 0, 0.65)",
					marginBottom: 10,
				}}
			>
				{text}
			</p>
			<SubscriptionButton label={label} onClick={onClick} />
		</div>
	);
};

type ChatTopbarProps = {
	onResetChat: () => void;
};

export const ChatTopbar = ({ onResetChat }: ChatTopbarProps) => {
	const { user } = useUser();
	const chatAccess = useChatAccess();
	const { t } = useTranslation();
	const { t: p } = useTranslation();
	const {
		Subscription: { subscriptionCheckoutSession, costumerPortal },
	} = useApi("ChatTopbar");

	const [isNewChatHovered, setIsNewChatHovered] = useState(false);
	const [disable, setDisable] = useState(false);

	useEffect(() => {
		const addListenerToUserName = () => {
			const userProfileButton = document.querySelector(".cl-userButtonTrigger");
			const userProfileLabel = document.querySelector(".cl-userButtonOuterIdentifier");

			if (userProfileLabel) {
				const name = userProfileLabel.textContent;
				const span = document.createElement("span");
				span.textContent = name ?? "";
				span.classList.add("cl-userButtonOuterIdentifier");
				userProfileButton?.appendChild(span);
				userProfileLabel.remove();
			}
		};

		// Limit the scope by observing a specific container
		const targetNode = document.querySelector(".specificContainerSelector");
		if (!targetNode) return;

		const observer = new MutationObserver((mutations) => {
			for (const mutation of mutations) {
				if (mutation.addedNodes.length > 0 && document.querySelector(".cl-userButtonTrigger")) {
					addListenerToUserName();
					observer.disconnect();
					break;
				}
			}
		});

		observer.observe(targetNode, { childList: true, subtree: true });

		// Set a timeout to stop observing after 10 seconds if the element is not found
		const timeout = setTimeout(() => observer.disconnect(), 10000);

		return () => {
			observer.disconnect();
			clearTimeout(timeout);
		};
	}, []);

	return (
		<div data-ui="ChatTopbar" className={clsx(styles.container, chatAccess.isSubscriptionActive ? styles.fixH : "")}>
			<div className={clsx(styles.spacing, !!chatAccess.isFreeTrialActive && styles.withContent)}>
				<SectionTitle className={styles.title} title={t("chatWithWilliam")} icon={<ChatDialogIcon />} />

				<div className={styles.divider} />

				<Tooltip placement="left" offset={5}>
					<TooltipTrigger>
						<div onMouseEnter={() => setIsNewChatHovered(true)} onMouseLeave={() => setIsNewChatHovered(false)}>
							<Button
								style={{
									paddingRight: "0",
									paddingLeft: "0",
								}}
								label=""
								onClick={onResetChat}
								icon={NewChat(isNewChatHovered)}
							/>
						</div>
					</TooltipTrigger>
					<TooltipContentContainer>
						<TooltipContent title={t("newChat")} />
					</TooltipContentContainer>
				</Tooltip>

				{user && (
					<div className={clsx(buttonStyles.container, buttonStyles.primary, styles.userButtonContainer)} id="nonClickableUserButtonContainer">
						<UserButton showName>
							<UserButton.UserProfilePage label={p("subscription")} labelIcon={<DotIcon />} url="terms">
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "2rem",
									}}
								>
									<div style={{ textAlign: "center" }}>
										<h1 style={{ fontWeight: 600, fontSize: "2rem" }}>{p("williamPremium")}</h1>
										<p style={{ color: "rgba(0, 0, 0, 0.65)" }}>{t("manageSubscription")}</p>
									</div>
									<Item
										condition={chatAccess.isFreeTrialActive}
										text={
											<>
												{p("itLeft")}
												<b>&nbsp;{TrialDays - chatAccess.daysAfterRegistration}&nbsp;</b>
												{p("daysOfTrial")}
											</>
										}
										label="upgradeToPremium"
										onClick={() => {
											subscriptionCheckoutSession().then((url) => {
												window.location.href = url;
											});
										}}
									/>
									<Item
										condition={!chatAccess.hasAccessToChat}
										text={p("youDontHaveAccess")}
										label="manageSubscription"
										onClick={() => {
											subscriptionCheckoutSession().then((url) => {
												window.location.href = url;
											});
										}}
									/>
									<div
										style={{
											border: "1px solid rgba(0, 0, 0, 0.1)",
											padding: "20px 10px",
											borderRadius: 10,
											display: chatAccess.isSubscriptionActive ? "flex" : "none",
										}}
									>
										<p
											style={{
												color: "rgba(0, 0, 0, 0.65)",
												marginBottom: 10,
											}}
										>
											{p("youHaveAccess")}
										</p>
										<SubscriptionButton
											label="manageSubscription"
											disable={disable}
											onClick={() => {
												setDisable(true);
												costumerPortal()
													.then((url) => {
														window.location.href = url;
													})
													.catch((error) => {
														console.log(error);
														toast.error("Error. Try again");
													});
											}}
										/>
									</div>
								</div>
							</UserButton.UserProfilePage>
						</UserButton>
					</div>
				)}
			</div>
			<FreeTrialInfo chatAccess={chatAccess} />
		</div>
	);
};
