import clsx from "clsx";
import { t } from "i18next";
import { useState } from "react";

import { RemoveIcon } from "../../../../assets/icons/Icons";
import styles from "./CloseButton.module.scss";

type CloseButtonProps = {
	onClose: () => void;
	className?: string;
	height?: number;
};

export const CloseButton = ({ onClose, className, height }: CloseButtonProps) => {
	const [isHover, setIsHover] = useState(false);

	return (
		<button
			type="button"
			className={clsx(styles.closeButton, isHover && styles.active, className)}
			onClick={onClose}
			title={t("close")}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<RemoveIcon height={height} />
		</button>
	);
};
