import clsx from "clsx";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";
import type { ChatHistory } from "src/api";

import { RomanEmperor } from "../../../assets/gfx/images";
import { Gradient } from "../../../components/atoms/_Pure/Gradient/Gradient";
import { Hint } from "../../../components/atoms/_Pure/Hint/Hint";
import { InfinityScroll } from "../../../components/atoms/_Pure/InfinityScroll/InfinityScroll";
import { HistorySkeletonLoader } from "../../../components/atoms/_Pure/SkeletonLoaders/HistorySkeletonLoader";
import { AppInnerStates, useAppContext } from "../../../state/AppContext";
import { useChatContext } from "../../../state/ChatContext";
import { localStorageHelper } from "../../../utils/localStorageHelper";
import { HintHistory } from "../Hints/Hints";
import styles from "./HistoryExplorer.module.scss";

export const HistoryExplorer = () => {
	const {
		dispatch,
		state: { currentChatHistory, chatHistories, innerState },
		isNoMoreHistories,
	} = useAppContext();

	const { t } = useTranslation();
	return (
		<>
			<Gradient />
			<div className={clsx(styles.container)}>
				<HintHistory />
				<InfinityScroll<ChatHistory>
					data={chatHistories}
					loadMore={() => {
						if (!isNoMoreHistories) dispatch({ type: AppInnerStates.MORE_HISTORY });
					}}
					keySelector={(item) => item.id ?? uuid()}
					renderItem={(h, index) => (
						<SingleHistory isCurrent={!!currentChatHistory && currentChatHistory === h.id} key={`${h.id}-${index}`} history={h} />
					)}
				/>
				{innerState === AppInnerStates.MORE_HISTORY && <HistorySkeletonLoader />}
				{innerState === AppInnerStates.MORE_HISTORY && <HistorySkeletonLoader />}
				{innerState === AppInnerStates.MORE_HISTORY && <HistorySkeletonLoader />}
				{innerState === AppInnerStates.MORE_HISTORY && <HistorySkeletonLoader />}
				{innerState === AppInnerStates.MORE_HISTORY && <HistorySkeletonLoader />}
			</div>
		</>
	);
};

type SingleHistoryProps = {
	history: ChatHistory;
	isCurrent: boolean;
};
const SingleHistory = ({ history, isCurrent }: SingleHistoryProps) => {
	const {
		state: { chatHistories },
	} = useAppContext();
	const { newChat } = useChatContext();
	const setCurrentHistory = (id: string) => {
		const current = chatHistories.find((h) => h.id === id);

		if (!current) {
			return;
		}

		newChat(current);
	};

	const date = dayjs(history.createdAt);

	let summary = history.summary;
	if (!summary) {
		const lastMessage = history.messages?.[history.messages.length - 1]?.message;
		if (lastMessage) {
			summary = lastMessage.length > 120 ? `${lastMessage.substring(0, 120)}...` : lastMessage;
		}
	}
	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div className={clsx(styles.singleHistoryContainer, isCurrent ? styles.selected : "")} onClick={() => setCurrentHistory(history.id ?? "")}>
			<div className={styles.time}>{date.format("DD.MM.YYYY r. HH:mm")}</div>
			<div className={styles.summary}>{summary}</div>
			<div className={styles.brief}>{history.brief}</div>
		</div>
	);
};
