import clsx from "clsx";
import { useState } from "react";

import { RomanEmperor } from "../../assets/gfx/images";
import { ChatDialogIcon, PaperIcon } from "../../assets/icons/Icons";
import { AdditionalBox } from "../../components/atoms/_Pure/AdditionalBox/AdditionalBox";
import { Gradient } from "../../components/atoms/_Pure/Gradient/Gradient";
import { Hint } from "../../components/atoms/_Pure/Hint/Hint";
import { Chat } from "../../components/organisms/Chat/Chat";
import { ChatSideNav } from "../../components/organisms/ChatSideNav/ChatSideNav";
import { DocumentExplorerV2 } from "../../components/organisms/DocumentExplorer/DocumentExplorerV2";
import { HistoryExplorer } from "../../components/organisms/HistoryExplorer/HistoryExplorer";
import { PaymentInfoModal } from "../../components/organisms/PaymentInfoModal/PaymentInfoModal";
import { useChatAccess } from "../../hooks/useChatAccess";
import { useRegistrationMetadata } from "../../hooks/useRegistrationMetadata";
import { AppInnerStates, useAppContext } from "../../state/AppContext";
import { FillMetaDataPage } from "../FillMetadata/FillMetaDataPage";
import styles from "./ChatPage.module.css";

export const ChatPage = () => {
	const {
		state: { currentBox, modal, needFillMetadata },
		dispatch,
	} = useAppContext();

	const isAdditionalBoxVisible = Boolean(currentBox !== "None");

	const [modalShowed, setModalShowed] = useState(false);

	const chatAccess = useChatAccess();

	//dispatch({ type: AppInnerStates.SET_MODAL, payload: "PaymentInfo" });

	/**
	 * this fn is workaround
	 * we had bug with message. Sometimes it was not rerendered
	 * we don't know why, so we added key to Chat component
	 * and we change it when we want to rerender chat
	 * */
	const [chatKeyProp, setKeyChatProp] = useState<number>(1);
	const rerenderChatComponent = () => {
		setKeyChatProp((prev) => prev + 1);
	};

	//useRegistrationMetadata();

	//if (needFillMetadata) return <FillMetaDataPage />;

	let additionalBox = null;
	switch (currentBox) {
		case "ActsBrowser":
			additionalBox = (
				<AdditionalBox
					visible={isAdditionalBoxVisible}
					icon={<PaperIcon />}
					titleKey="sourceBrowser"
					subTitleKey="sourceBrowserSubTitle"
					onClose={() => {
						dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "None" } });
					}}
				>
					<DocumentExplorerV2 />
				</AdditionalBox>
			);
			break;
		case "ChatHistory":
			additionalBox = (
				<AdditionalBox
					visible={isAdditionalBoxVisible}
					icon={<ChatDialogIcon />}
					titleKey="historyBrowser"
					subTitleKey="historyBrowserSubTitle"
					onClose={() => {
						dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "None" } });
					}}
				>
					<HistoryExplorer />
				</AdditionalBox>
			);
			break;
		default:
			additionalBox = (
				<AdditionalBox
					visible={false}
					icon={null}
					titleKey=""
					onClose={() => {
						dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "None" } });
					}}
				>
					<div />
				</AdditionalBox>
			);
	}

	return (
		<div className={clsx([styles.container, isAdditionalBoxVisible && styles.withGap])} data-box-model={currentBox}>
			{modal === "PaymentInfo" ? (
				<PaymentInfoModal
					onClose={() => {
						chatAccess.closeEarlyModal();
						setModalShowed(true);
					}}
				/>
			) : null}
			<ChatSideNav />
			{additionalBox}
			<Chat key={chatKeyProp} limitedSpace={isAdditionalBoxVisible} rerenderChatComponent={rerenderChatComponent} />
		</div>
	);
};
