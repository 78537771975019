import type React from "react";
import { useState } from "react";

import { WilliamLogo } from "../../../../assets/icons/Icons";
import styles from "./GeneralLoader.module.css";

type GeneralLoaderProps = {
	children?: React.ReactNode;
	isVisible?: boolean;
};

export const GeneralLoader: React.FC = () => {
	// Możesz użyć ustalonej liczby kafelków, ale nie musisz jej precyzyjnie określać
	const tiles = Array.from({ length: 3000 }); // Generujemy dużą ilość kafelków, aby zawsze wypełniały ekran

	return (
		<div className={styles.generalLoader}>
			<div className={styles.logoContainer}>
				{/* Twój logo komponent */}
				<WilliamLogo className={styles.loader} />
			</div>
			{/* 			
			<div className={styles.tileContainer}>
				{tiles.map((_, index) => (
					<Tile key={index} index={index} />
				))}
			</div> */}
		</div>
	);
};

interface TileProps {
	index: number;
}

export const Tile: React.FC<TileProps> = ({ index }) => {
	const [isHovered, setIsHovered] = useState(false);

	const gradients = [
		"linear-gradient(110deg, rgba(0, 161, 223, 0.5), rgba(27, 121, 193, 0.5))",
		"linear-gradient(110deg, rgba(27, 121, 193, 0.5), rgba(57, 78, 161, 0.5))",
		"linear-gradient(110deg, rgba(57, 78, 161, 0.5), rgba(84, 31, 131, 0.5))",
		"linear-gradient(110deg, rgba(84, 31, 131, 0.5), rgba(0, 161, 223, 0.5))",
	];

	const hoverGradients = [
		"linear-gradient(110deg, rgba(0, 161, 223, 0.8), rgba(27, 121, 193, 0.8))",
		"linear-gradient(110deg, rgba(27, 121, 193, 0.8), rgba(57, 78, 161, 0.8))",
		"linear-gradient(110deg, rgba(57, 78, 161, 0.8), rgba(84, 31, 131, 0.8))",
		"linear-gradient(110deg, rgba(84, 31, 131, 0.8), rgba(0, 161, 223, 0.8))",
	];

	const backgroundImage = isHovered ? hoverGradients[index % 4] : gradients[index % 4];

	return (
		<div
			className={styles.tile}
			style={{
				backgroundImage,
				opacity: isHovered ? 1 : 0,
				transition: isHovered ? "opacity 0ms" : "opacity 1500ms ease, background-image 300ms ease",
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		/>
	);
};
