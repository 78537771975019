import { SignUp } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import signup from "../../../assets/signup.webp";
import { localStorageHelper } from "../../../utils/localStorageHelper";
import { ClerkWrapper } from "../ClerkWrapper/ClerkWrapper";
import styles from "./CustomSignUp.module.scss";
import { CreateNewField } from "./tools";

export const CustomSignUp = () => {
	const { t } = useTranslation();

	const submitButtonDisabled = (value: boolean) => {
		const submitButton: HTMLButtonElement | null = document.querySelector(".cl-formButtonPrimary");

		if (!submitButton) return;
		submitButton.disabled = value;
	};

	const addProfessionSelect = () =>
		CreateNewField({
			nameKey: "userProfesion",
			innerHtml: t("professionalStatus"),
			required: true,
			onChange: (e) => {
				localStorageHelper.userMetadata.update({ userProfession: e.value });
			},
			options: [
				{ value: "legalCounsel", label: t("userType.legalCounsel") },
				{ value: "legalTrainee", label: t("userType.legalTrainee") },
				{ value: "advocate", label: t("userType.advocate") },
				{ value: "advocateTrainee", label: t("userType.advocateTrainee") },
				{ value: "lawyer", label: t("userType.lawyer") },
				{ value: "lawStudent", label: t("userType.lawStudent") },
				{ value: "taxAdvisor", label: t("userType.taxAdvisor") },
				{ value: "notary", label: t("userType.notary") },
				{ value: "notaryTrainee", label: t("userType.notaryTrainee") },
				{ value: "judge", label: t("userType.judge") },
				{ value: "refJudge", label: t("userType.refJudge") },
				{ value: "judgeSupport", label: t("userType.judgeSupport") },
				{ value: "judicialTrainee", label: t("userType.judicialTrainee") },
				{ value: "courtBailiff", label: t("userType.courtBailiff") },
				{ value: "courtBailiffTrainee", label: t("userType.courtBailiffTrainee") },
				{ value: "other", label: t("userType.other") },
			],
		});

	const addLawFirmName = () =>
		CreateNewField({
			nameKey: "lawFirmName",
			innerHtml: t("lawFirmName"),
			onChange: (e) => {
				localStorageHelper.userMetadata.update({ lawFirmName: e.value });
			},
			type: "text",
		});

	const addNumberOfLawyers = () =>
		CreateNewField({
			nameKey: "numberOfLawyers",
			innerHtml: t("numberOfLawyers"),
			onChange: (e) => {
				localStorageHelper.userMetadata.update({ numerOfLawyers: e.value });
			},
			type: "number",
			options: [
				{ value: "0", label: "0" },
				{ value: "5", label: "1-5" },
				{ value: "10", label: "6-10" },
				{ value: "150", label: "11-50" },
				{ value: "100", label: "51-100" },
				{ value: "500", label: "101-500" },
				{ value: "more", label: "500+" },
			],
		});

	const addTermCheckbox = () =>
		CreateNewField({
			nameKey: "termCheckbox",
			innerHtml: `${t("termCheckboxContent1")} <a class="${styles.link}" target="_blank" href="https://william.legal/terms">${t("termCheckboxContent2")}</a> ${t("and2")} <a target="_blank" class="${styles.link}" href="https://william.legal/privacy">${t("termCheckboxContent3")}</a> ${t("termCheckboxContent4")}*`,
			required: true,
			onChange: (e) => {
				if (e instanceof HTMLInputElement) {
					submitButtonDisabled(!e?.checked);
				}
			},
			type: "checkbox",
		});

	const addMarketingCheckbox = () =>
		CreateNewField({
			nameKey: "marketingCheckbox",
			innerHtml: t("marketingContent"),
			required: false,
			onChange: (e) => {
				if (e instanceof HTMLInputElement) {
					localStorageHelper.userMetadata.update({ marketingCheckbox: e.checked });
				}
			},
			type: "checkbox",
		});

	const check = () => {
		const form = document.querySelector(".cl-form");

		const submitButton = document.querySelector(".cl-formButtonPrimary");

		const passwordRow = document.querySelector(".cl-formFieldRow__password") as HTMLInputElement;

		if (passwordRow) {
			passwordRow?.parentElement?.insertBefore(addProfessionSelect(), passwordRow);
			passwordRow?.parentElement?.insertBefore(addLawFirmName(), passwordRow);
			passwordRow?.parentElement?.insertBefore(addNumberOfLawyers(), passwordRow);
		}

		const url = window.location.href;
		const isNoStandardUrl = url.includes("verify-phone-number") || url.includes("verify-email-address");

		if (!isNoStandardUrl) {
			submitButton?.parentElement?.insertBefore(addTermCheckbox(), submitButton);
			submitButton?.parentElement?.insertBefore(addMarketingCheckbox(), submitButton);

			submitButton?.parentElement?.insertBefore(document.createElement("br"), submitButton);
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const interval = setInterval(() => {
			const element = document.querySelector(".cl-form");

			if (!element) {
				return;
			}

			const termCB = document.getElementById("termCheckbox");

			if (termCB) {
				return;
			}

			check();
			submitButtonDisabled(true);
		}, 1);

		return () => clearInterval(interval);
	}, []);

	return (
		<ClerkWrapper img={signup} headerKey="signUpText1" textKey="signUpText2" height="100%">
			<SignUp signInUrl="/auth/signin" />
		</ClerkWrapper>
	);
};
