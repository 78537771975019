import { cloneDeep } from "lodash";

import type { UserMetaData } from "../hooks/useRegistrationMetadata";

interface LocalStorageItem<T = unknown> {
	get: () => T | null;
	set: (value: T) => void;
	remove: () => void;
	hasValueOf: (value?: T) => boolean;
	isUndefinedOrNull: () => boolean;
	update: (data: Partial<T> | T) => void;
}

const localStorageHandler = <T = unknown,>(key: string): LocalStorageItem<T> & { update: (data: Partial<T> | T) => void } => {
	if (typeof window === "undefined") {
		return {
			get: () => null,
			set: () => null,
			remove: () => null,
			hasValueOf: () => false,
			isUndefinedOrNull: () => false,
			update: () => {},
		};
	}

	const get = (): T | null => {
		try {
			const value = localStorage.getItem(key);
			return value ? JSON.parse(value) : null;
		} catch (error) {
			return null;
		}
	};

	const set = (value: T): void => {
		try {
			localStorage.setItem(key, JSON.stringify(value));
		} catch (error) {
			if (error instanceof DOMException && error.name === "QuotaExceededError") {
				console.error("LocalStorage limit exceeded");
			} else {
				console.error("Error saving to localStorage", error);
			}
		}
	};

	const remove = (): void => {
		localStorage.removeItem(key);
	};

	const isUndefinedOrNull = (): boolean => {
		const value = get();
		return value === null || value === undefined;
	};

	const hasValueOf = (value?: T): boolean => {
		if (value === undefined) {
			return !isUndefinedOrNull();
		}
		return JSON.stringify(get()) === JSON.stringify(value);
	};

	const update = (data: Partial<T> | T): void => {
		const currentValue = get();

		// Type check for currentValue and incoming data
		if (typeof currentValue === "object" && currentValue !== null && typeof data === "object" && data !== null) {
			// Perform deep merge for objects using cloneDeep
			const updatedValue = { ...cloneDeep(currentValue), ...data };
			set(updatedValue as T);
		} else if (typeof currentValue !== typeof data) {
			console.warn(`Type mismatch: Cannot update ${typeof currentValue} with ${typeof data}`);
		} else {
			// Overwrite for primitive types
			set(data as T);
		}
	};

	return { get, set, remove, hasValueOf, isUndefinedOrNull, update };
};

export const localStorageHelper = {
	isDarkMode: localStorageHandler<boolean>("william_isDarkModeActive"),
	languageSystem: localStorageHandler<string>("william_language"),
	userMetadata: localStorageHandler<UserMetaData>("william_userMetadata"),
	visitedLinks: localStorageHandler<string[]>("william_visitedLinks"),
	hints: localStorageHandler<Record<string, boolean>>("william_hints"),
};
