import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ClerkWrapper.module.scss";

type ClerkWrapperProps = React.PropsWithChildren<{
	img: string;
	headerKey: string;
	textKey: string;
	height?: string;
}>;

export const ClerkWrapper: React.FC<ClerkWrapperProps> = ({ children, img, headerKey, textKey, height }: ClerkWrapperProps) => {
	const { t } = useTranslation();

	const [url, setUrl] = useState(window.location.href);

	// Update URL state whenever the URL changes
	useEffect(() => {
		const handleUrlChange = () => {
			setUrl(window.location.href);
		};

		// Listen for 'popstate' events (for back/forward navigation)
		window.addEventListener("popstate", handleUrlChange);

		// Listen for 'hashchange' if URL fragments are changing
		window.addEventListener("hashchange", handleUrlChange);

		// Clean up event listeners on unmount
		return () => {
			window.removeEventListener("popstate", handleUrlChange);
			window.removeEventListener("hashchange", handleUrlChange);
		};
	}, []);

	// Check if the URL contains specific paths
	const isNoStandardUrl = url.includes("verify-phone-number") || url.includes("verify-email-address");

	return (
		<div className={styles.container} style={{ height: height ? height : "unset" }}>
			{!isNoStandardUrl && (
				<div className={[styles.imgContainer].join(" ")}>
					<div className={[styles.img, styles.visible].join(" ")}>
						<img src={img} alt="Authentication" />
						<div className={[styles.imgText, styles.fix].join(" ")}>
							<h2 className={styles.title}>{t(headerKey)}</h2>
							<span style={{ color: "#8a94a6" }}>{t(textKey)}</span>
						</div>
					</div>
				</div>
			)}
			<div className={clsx(styles.mainContent, isNoStandardUrl && styles.fix)}>{children}</div>
		</div>
	);
};
