import styles from "./CustomSignUp.module.scss";

type Option = {
	value: string; // The key that is stored
	label: string; // The displayed text
};

type CreateNewFieldProps = {
	nameKey: string;
	innerHtml: string;
	onChange?: (v: HTMLInputElement | HTMLSelectElement) => void;
	required?: boolean;
	type?: string;
	options?: Option[];
};

export const CreateNewField = ({ nameKey, innerHtml, required, onChange, type, options }: CreateNewFieldProps) => {
	const inputContainer = document.createElement("div");
	inputContainer.id = `${nameKey}Container`;
	inputContainer.className = "cl-formFieldRow cl-formField";

	const label = document.createElement("label");
	label.innerHTML = innerHtml;
	label.className = `${styles.label} cl-formFieldLabel`;
	label.htmlFor = nameKey;

	const input = document.createElement(options ? "select" : "input");
	const isInput = input instanceof HTMLInputElement;
	input.id = nameKey;
	input.name = nameKey;
	input.className = `${styles.input} cl-input cl-formFieldInput`;
	input.required = required ?? false;
	input.autocomplete = "off";
	input.addEventListener("change", (e) => {
		if (options) {
			onChange?.(e.target as HTMLSelectElement);
			return;
		}
		onChange?.(e.target as HTMLInputElement);
	});

	if (type) {
		input.setAttribute("type", type);
	}

	if (type === "number" && isInput) {
		input.min = "0";
	}

	if (type === "checkbox") {
		label.style.marginBottom = "3px";
		input.style.transform = "scale(1.2) translate(0px, 2px)";

		inputContainer.className = styles.checkbox;
		label.className = styles.termCheckboxLabel;
		input.className = styles.termCheckboxInput;
	}

	if (options) {
		for (const { value, label } of options) {
			const option = document.createElement("option");
			option.value = value;
			option.textContent = label;
			input.appendChild(option);
		}
	}

	input.value = "";

	inputContainer.append(label);

	if (type === "password") {
		const fieldGroup = document.createElement("div");
		fieldGroup.className = "cl-formFieldInputGroup";
		fieldGroup.style.display = "flex";
		fieldGroup.style.flexDirection = "row";
		fieldGroup.style.alignItems = "center";
		fieldGroup.style.justifyContent = "center";
		fieldGroup.style.position = "relative";

		const showPassword = document.createElement("button");
		showPassword.className = "cl-formFieldInputShowPasswordButton cl-button";
		showPassword.style.margin = "0px 0.25rem 0px 0px";
		showPassword.style.padding = "0.25rem 0.75rem";
		showPassword.style.borderRadius = "0.375rem";
		showPassword.style.borderWidth = "0px";
		showPassword.style.outline = "0px";
		showPassword.style.userSelect = "none";
		showPassword.style.cursor = "pointer";
		showPassword.style.position = "absolute";
		showPassword.style.right = "0px";
		showPassword.style.backgroundColor = "unset";

		showPassword.setAttribute("aria-label", "Show password");
		showPassword.setAttribute("tabindex", "-1");

		showPassword.innerHTML =
			'<svg style="width:16px" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="cl-formFieldInputShowPasswordIcon"><path d="M8 9.607c.421 0 .825-.17 1.123-.47a1.617 1.617 0 0 0 0-2.273 1.578 1.578 0 0 0-2.246 0 1.617 1.617 0 0 0 0 2.272c.298.302.702.471 1.123.471Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M2.07 8.38a1.073 1.073 0 0 1 0-.763 6.42 6.42 0 0 1 2.334-2.99A6.302 6.302 0 0 1 8 3.5c2.704 0 5.014 1.71 5.93 4.12.094.246.093.518 0 .763a6.418 6.418 0 0 1-2.334 2.99A6.301 6.301 0 0 1 8 12.5c-2.704 0-5.013-1.71-5.93-4.12ZM10.54 8c0 .682-.267 1.336-.743 1.818A2.526 2.526 0 0 1 8 10.571c-.674 0-1.32-.27-1.796-.753A2.587 2.587 0 0 1 5.459 8c0-.682.268-1.336.745-1.818A2.525 2.525 0 0 1 8 5.429c.674 0 1.32.27 1.797.753.476.482.744 1.136.744 1.818Z"/></svg>';

		showPassword.addEventListener("click", (e) => {
			e.preventDefault();

			if (input.getAttribute("type") === "password") {
				input.setAttribute("type", "text");
				showPassword.setAttribute("aria-label", "Hide password");
			} else {
				input.setAttribute("type", "password");
				showPassword.setAttribute("aria-label", "Show password");
			}
		});

		fieldGroup.append(input);
		fieldGroup.append(showPassword);
		inputContainer.append(fieldGroup);
	} else {
		inputContainer.append(input);
	}

	return inputContainer;
};
