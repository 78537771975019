import type React from "react";
import { createContext, type ReactNode, useContext, useEffect, useReducer, useState } from "react";
import uuid from "react-uuid";

import type { ChatHistory } from "../api";
import { useApi } from "../hooks/useApi";
import { cookieHelper } from "../utils/cookieHelper";
import { localStorageHelper } from "../utils/localStorageHelper";
import type { ActWithIndex, ChatMessageDto } from "./ChatContext";

export type BoxType = "None" | "ActsBrowser" | "ChatHistory";
type ModalType = "None" | "PaymentInfo";

export enum AppInnerStates {
	NONE = "NONE",
	SET_USER_EMAIL = "SET_USER_EMAIL",
	SET_BOX = "SET_BOX",
	NEW_CHAT = "NEW_CHAT",
	SET_ACTS = "SET_ACTS",
	SET_CURRENT_ACT = "SET_CURRENT_ACT",
	MORE_HISTORY = "MORE_HISTORY",
	SET_HISTORY = "SET_HISTORY",
	SET_CURRENT_HISTORY = "SET_CURRENT_HISTORY",
	ADD_TO_CURRENT_HISTORY = "ADD_TO_CURRENT_HISTORY",
	SET_MODAL = "SET_MODAL",
	NEED_UPDATE_METADATA = "NEED_UPDATE_METADATA",
}

export interface AppState {
	innerState: AppInnerStates;
	chatHistories: ChatHistory[];
	currentChatHistory: string | null;
	acts: ActWithIndex[];
	currentActGuid: string | null;
	currentBox: BoxType;
	userEmail: string;
	historyToAppend: ChatMessageDto[];
	modal: ModalType;
	needFillMetadata: boolean;
}

export type Action =
	| { type: AppInnerStates.NONE }
	| { type: AppInnerStates.SET_MODAL; payload: ModalType }
	| { type: AppInnerStates.SET_USER_EMAIL; payload: { email: string } }
	| { type: AppInnerStates.SET_BOX; payload: { boxType: BoxType } }
	| { type: AppInnerStates.NEW_CHAT; payload: { id: string } }
	| { type: AppInnerStates.SET_ACTS; payload: { msg: ActWithIndex[] } }
	| { type: AppInnerStates.SET_CURRENT_ACT; payload: { actGuid: string | null } }
	| { type: AppInnerStates.MORE_HISTORY }
	| { type: AppInnerStates.SET_HISTORY; payload: { history: ChatHistory[] } }
	| { type: AppInnerStates.SET_CURRENT_HISTORY; payload: { historyId: string | null } }
	| { type: AppInnerStates.ADD_TO_CURRENT_HISTORY; payload: ChatMessageDto[] }
	| { type: AppInnerStates.NEED_UPDATE_METADATA; payload: boolean };

function distinctArray<T, T2>(arr: T[], selector: (item: T) => T2): T[] {
	const seen = new Set<T2>();
	return arr.filter((item) => {
		const key = selector(item);
		if (seen.has(key)) {
			return false;
		}
		seen.add(key);
		return true;
	});
}

type AppStateContextType = {
	state: AppState;
	dispatch: React.Dispatch<Action>;
	refreshCurrentHistory: () => void;
	isLoadingHistories: boolean;
	isNoMoreHistories: boolean;
};

export const AppStateContext = createContext<AppStateContextType | undefined>(undefined);

type AppStateProviderProps = {
	children: ReactNode;
};

const initState: AppState = {
	innerState: AppInnerStates.NONE,
	chatHistories: [],
	currentChatHistory: null,
	acts: [],
	currentActGuid: null,
	currentBox: "None",
	userEmail: "",
	historyToAppend: [],
	modal: "None",
	needFillMetadata: true,
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(
		(state: AppState, action: Action): AppState => {
			//console.log("App state reducer: ", action.type, action);
			switch (action.type) {
				case AppInnerStates.SET_MODAL:
					return { ...state, modal: action.payload, innerState: AppInnerStates.SET_MODAL };
				case AppInnerStates.SET_USER_EMAIL:
					return { ...state, userEmail: action.payload.email, innerState: AppInnerStates.SET_USER_EMAIL };
				case AppInnerStates.SET_BOX:
					cookieHelper.box.set(action.payload.boxType);
					return { ...state, currentBox: action.payload.boxType, innerState: AppInnerStates.SET_BOX };
				case AppInnerStates.NEW_CHAT:
					return {
						...state,
						currentChatHistory: action.payload.id,
						innerState: AppInnerStates.NEW_CHAT,
					};
				case AppInnerStates.SET_ACTS: {
					const acts = distinctArray(action.payload.msg, (a) => a.guid);
					return { ...state, acts: acts, innerState: AppInnerStates.SET_ACTS };
				}
				case AppInnerStates.SET_CURRENT_ACT:
					return { ...state, currentActGuid: action.payload.actGuid, innerState: AppInnerStates.SET_CURRENT_ACT };

				case AppInnerStates.SET_HISTORY:
					return { ...state, chatHistories: action.payload.history, innerState: AppInnerStates.SET_HISTORY };
				case AppInnerStates.MORE_HISTORY:
					return { ...state, innerState: AppInnerStates.MORE_HISTORY };
				case AppInnerStates.SET_CURRENT_HISTORY:
					cookieHelper.chat.set(action.payload.historyId ?? "");
					return { ...state, currentChatHistory: action.payload.historyId, innerState: AppInnerStates.SET_CURRENT_HISTORY };
				case AppInnerStates.ADD_TO_CURRENT_HISTORY:
					return { ...state, historyToAppend: action.payload, innerState: AppInnerStates.ADD_TO_CURRENT_HISTORY };

				case AppInnerStates.NEED_UPDATE_METADATA:
					return { ...state, needFillMetadata: action.payload, innerState: AppInnerStates.NONE };

				default:
					return { ...state, innerState: AppInnerStates.NONE };
			}
		},
		{ ...initState }
	);

	//If there is value in cookieHelper.box and the state value of box is different than the one in cookieHelper.box, then set the state value of box to the one in cookieHelper.box
	if (state.currentBox !== cookieHelper.box.get()) {
		dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: cookieHelper.box.get() ?? "None" } });
	}

	const [isLoadingHistories, setIsLoadingHistories] = useState<boolean>(false);
	const [hasUserDownloadHistoryEver, setHasUserDownloadHistoryEver] = useState<boolean>(false);
	const [token, setToken] = useState<string | null | undefined>(undefined);

	const {
		Chat: { histories },
	} = useApi("HistoryPage");

	useEffect(() => {
		//console.log("AppContext - useEffect of ", state.innerState);
		switch (state.innerState) {
			case AppInnerStates.NEW_CHAT: {
				setToken(null);
				return;
			}
			case AppInnerStates.MORE_HISTORY: {
				if (isLoadingHistories) return; //Jesteśmy w trakcie pobierania historii

				if (state.currentBox !== "ChatHistory") return; //Nie jesteśmy w historii

				//Nie ma więcej historii
				if (token === null && token !== undefined) {
					dispatch({ type: AppInnerStates.NONE });
					return;
				}

				setIsLoadingHistories(true);

				histories(token)
					.then((res) => {
						const { chatHistory, continuationToken } = res;
						setToken(continuationToken);
						setHasUserDownloadHistoryEver(true);
						if (chatHistory && chatHistory.length > 0) {
							dispatch({ type: AppInnerStates.SET_HISTORY, payload: { history: [...state.chatHistories, ...chatHistory] } });
						}
					})
					.catch((e) => {
						console.error("Error fetching more histories", e);
					})
					.finally(() => {
						setIsLoadingHistories(false);
						dispatch({ type: AppInnerStates.NONE });
					});

				return;
			}

			case AppInnerStates.ADD_TO_CURRENT_HISTORY: {
				const { currentChatHistory, historyToAppend, chatHistories } = state;

				const isCurrentHistoryInHistory = chatHistories.some((h) => h.id === currentChatHistory);

				if (isCurrentHistoryInHistory) {
					const currentHistory = chatHistories.find((h) => h.id === currentChatHistory);
					if (!currentHistory) {
						return;
					}

					const newMessages = [...(currentHistory.messages ?? []), ...historyToAppend];
					const updatedHistory = { ...currentHistory, messages: newMessages };

					const updatedHistories = chatHistories.map((h) => (h.id === currentChatHistory ? updatedHistory : h));

					dispatch({ type: AppInnerStates.SET_HISTORY, payload: { history: updatedHistories } });
				} else if (!hasUserDownloadHistoryEver) {
					const id = currentChatHistory ?? uuid();
					const newHistory: ChatHistory = {
						id: id,
						messages: [...historyToAppend],
						createdAt: new Date().toISOString(),
					};

					const updatedHistories = [newHistory, ...chatHistories];

					dispatch({ type: AppInnerStates.SET_HISTORY, payload: { history: updatedHistories } });
					dispatch({ type: AppInnerStates.SET_CURRENT_HISTORY, payload: { historyId: id } });
				}

				dispatch({ type: AppInnerStates.NONE });

				return;
			}
			default: {
				return;
			}
		}
	});

	return (
		<AppStateContext.Provider
			value={{
				state,
				dispatch,
				isLoadingHistories,
				isNoMoreHistories: token === null,
				refreshCurrentHistory: async () => {
					let token: string | null | undefined = null;

					let myHistories: ChatHistory[] = [];
					let isAnyCurrentHistory = false;

					const conditions = [!myHistories.some((h) => state.chatHistories.some((h2) => h2.id === h.id)), !isAnyCurrentHistory];

					do {
						const { chatHistory, continuationToken } = await histories(token);
						token = continuationToken;
						myHistories = myHistories.concat(chatHistory ?? []);
						isAnyCurrentHistory = chatHistory?.some((h) => h.id === state.currentChatHistory) ?? false;
					} while (token && conditions[state.currentChatHistory ? 1 : 0]);

					if (!state.currentChatHistory) {
						setToken(undefined);
						dispatch({ type: AppInnerStates.SET_HISTORY, payload: { history: [] } });
						dispatch({ type: AppInnerStates.MORE_HISTORY });
					} else {
						//User ma już wybraną historię

						//Pobieramy naszą nowszą historię
						const newCurrentHistory = myHistories.find((h) => h.id === state.currentChatHistory);
						if (!newCurrentHistory) {
							return;
						}

						//Aktualizujemy tylko naszą historię
						const updatedHistories = state.chatHistories.map((history) => (history.id === newCurrentHistory.id ? newCurrentHistory : history));

						dispatch({ type: AppInnerStates.SET_HISTORY, payload: { history: updatedHistories } });
					}
				},
			}}
		>
			{children}
		</AppStateContext.Provider>
	);
};

export const useAppContext = () => {
	const context = useContext(AppStateContext);

	if (!context) {
		throw new Error("useAppContext must be used within a AppStateProvider");
	}

	return context;
};
