import { SignIn } from "@clerk/clerk-react";
import { useTranslation } from "react-i18next";

import signup from "../../../assets/signup.webp";
import { ClerkWrapper } from "../ClerkWrapper/ClerkWrapper";

export const CustomSignIn = () => {
	const { t } = useTranslation();

	return (
		<ClerkWrapper img={signup} headerKey="signUpText1" textKey="signUpText2" height="100vh">
			<SignIn signUpUrl="/auth/signup" />
		</ClerkWrapper>
	);
};
