import { SignedIn, SignedOut } from "@clerk/clerk-react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import { CustomSignIn } from "./components/atoms/Clerk/CustomSignIn";
import { CustomSignUp } from "./components/atoms/Clerk/CustomSignUp";
import { Layout } from "./components/Layout/Layout";
import { CenteredLayout } from "./components/templates/CenteredLayout/CenteredLayout";
import { useRedirectsFromStripe } from "./hooks/useRedirectsFromStripe";
import { useRegistrationMetadata } from "./hooks/useRegistrationMetadata";
import { ChatPage } from "./pages/chat/ChatPage";

export const MyRouter = () => {
	const isAuthorizationEnabled = import.meta.env.VITE_FEATURE_FLAG_IS_AUTHORIZATION_ENABLED === "true";

	//useRegistrationMetadata();
	useRedirectsFromStripe();

	const router = createBrowserRouter([
		{
			path: "/",
			element: <Layout />,
			children: [
				{
					index: true,
					element: isAuthorizationEnabled ? (
						<>
							<SignedIn>
								<ChatPage />
							</SignedIn>
							<SignedOut>
								<Navigate to="/auth/signin" />
							</SignedOut>
						</>
					) : (
						<>
							<ChatPage />
						</>
					),
				},
			],
		},
		{
			path: "/auth",
			element: <CenteredLayout />,
			children: [
				{
					path: "signin",
					element: <CustomSignIn />,
				},
				{
					path: "signup",
					element: <CustomSignUp />,
				},
			],
		},
		{
			path: "*",
			element: <Navigate to="/" />,
		},
	]);

	return <RouterProvider router={router} />;
};
