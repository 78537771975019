import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { RemoveIcon } from "../../../../assets/icons/Icons";
import { localStorageHelper } from "../../../../utils/localStorageHelper";
import { CloseButton } from "../CloseButton/CloseButton";
import styles from "./Hint.module.scss";

type HintProps = {
	id: string;
	title: string;
	subtitle: string;
	image?: string;
};

export const Hint = ({ id, title, subtitle, image }: HintProps) => {
	const { t } = useTranslation();

	const hints = localStorageHelper.hints?.get();

	const [isVisible, setIsVisible] = useState(hints?.[id] !== true);

	return (
		<div className={clsx([styles.container, !isVisible && styles.hidden])}>
			<div className={styles.imageContainer}>{image && <img src={image} alt={t("hint")} title={t("hint")} />} </div>
			<CloseButton
				height={16}
				className={styles.closeButton}
				onClose={() => {
					setIsVisible(false);
					localStorageHelper.hints?.set({ ...hints, [id]: true });
				}}
			/>
			<p className={styles.title}>{title}</p>
			<p className={styles.content}>{subtitle}</p>
		</div>
	);
};
