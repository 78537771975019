/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth, useUser } from "@clerk/clerk-react";
import { datadogRum } from "@datadog/browser-rum";
import { signal } from "@preact/signals-react";
import md5 from "md5";
import { useState } from "react";
import uuid from "react-uuid";

import type { CancelablePromise, ChatHistoryResponse, LawyerChatWithDocumentsCommand, LawyerChatWithDocumentsResponse } from "../api";
import { ApiError, OpenAPI } from "../api";
import type { ApiRequestOptions } from "../api/core/ApiRequestOptions";
import { request } from "../api/core/request";
import { API_URL } from "../constants";

//use react-signal
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const requests = signal<CancelablePromise<any>[]>([]);

export const useApi = (componentRoot: string) => {
	const { getToken } = useAuth();

	const { user } = useUser();

	const getProp = async <T,>(
		url: string,
		method: "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH",
		isJson: boolean,
		query?: Record<string, string>,
		body?: T,
		header?: Record<string, unknown>
	): Promise<ApiRequestOptions | null> => {
		let prop: ApiRequestOptions = {
			method: method,
			url: API_URL + url,
			query: { componentRoot: componentRoot },
		};

		//console.log("Requesting:", prop);

		if (query) {
			const newQuery = { ...query, componentRoot: componentRoot };

			prop = {
				...prop,
				query: newQuery,
			};

			//console.log("Query:", newQuery);
		}

		if (body) {
			prop = {
				...prop,
				body: JSON.stringify(body),
			};
			//console.log("Body:", body);
		}

		if (isJson) {
			prop = {
				...prop,
				mediaType: "application/json",
			};
			//console.log("Is JSON");
		}

		const token = await getToken();

		if (!token) return null;

		const rumContext = datadogRum.getGlobalContext();

		prop = {
			...prop,
			headers: {
				...prop.headers,
				...header,
				Authorization: `Bearer ${token}`,
				"x-datadog-trace-id": rumContext.trace_id,
				"x-datadog-parent-id": rumContext.span_id,
				"x-datadog-sampling-priority": "1",
			},
		};

		return prop;
	};

	const [isEndPointRunning, setIsEndPointRunning] = useState(false);

	const myRequest = async <T, R>(
		url: string,
		method: "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH",
		isJson: boolean,
		query?: Record<string, string>,
		body?: T,
		header?: Record<string, unknown>
	) => {
		try {
			const prop = await getProp<T>(url, method, isJson, query, body, header);

			if (!prop) throw new Error("No token");

			const myRequest = request<R>(OpenAPI, prop);

			requests.value.push(myRequest);

			return myRequest;
		} catch (error) {
			if (error instanceof ApiError) {
				//console.log("ApiError:", error.status, error.message, error.body);
			}

			//console.error("Error:", error);
			throw error;
		}
	};

	const cancelAllRequests = () => {
		//console.log("Cancelling all requests");
		if (requests.value.length <= 0) return;

		for (const request of requests.value) {
			request.cancel();
			//console.log("Cancelled request", request);
		}
		requests.value = [];
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const revalidateUserSubscription = (): CancelablePromise<any> => {
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		return myRequest<undefined, any>("/Subscription/revalidate-user-subscription", "GET", false) as CancelablePromise<any>;
	};

	const subscriptionCheckoutSession = (): CancelablePromise<string> => {
		return myRequest<undefined, string>("/Subscription/subscription-checkout-session", "GET", false) as CancelablePromise<string>;
	};

	const costumerPortal = (): CancelablePromise<string> => {
		return myRequest<undefined, string>("/Subscription/customer-portal", "GET", false) as CancelablePromise<string>;
	};

	const LawyerChatWithDocuments = (body: LawyerChatWithDocumentsCommand): CancelablePromise<LawyerChatWithDocumentsResponse | null> => {
		const lastMessageContent = body?.messages?.[body.messages?.length - 1]?.content;

		const requestId = `${user?.id ?? uuid()}-${body.id}-${md5(lastMessageContent ?? uuid())}`;
		const safeString = encodeURIComponent(requestId);

		return myRequest<LawyerChatWithDocumentsCommand, LawyerChatWithDocumentsResponse>("/LawyerChatWithDocuments", "POST", true, undefined, body, {
			"X-REQUEST-ID": btoa(safeString),
		}) as CancelablePromise<LawyerChatWithDocumentsResponse | null>;
	};
	const LawyerChatWithDocumentsHistories = (body: string | null | undefined, query?: Record<string, string>): CancelablePromise<ChatHistoryResponse> => {
		return myRequest<string | null, ChatHistoryResponse>(
			"/LawyerChatWithDocuments/history",
			"POST",
			true,
			query,
			body
		) as CancelablePromise<ChatHistoryResponse>;
	};

	return {
		cancelAllRequests,
		Subscription: {
			revalidateUserSubscription,
			subscriptionCheckoutSession,
			costumerPortal,
		},
		Chat: { askQuestion: LawyerChatWithDocuments, histories: LawyerChatWithDocumentsHistories },
	};
};
