import { useTranslation } from "react-i18next";

import { SmilingManX2 as SM, SmilingWomanX2 as SW } from "../../../assets/gfx/images";
import { Hint } from "../../atoms/_Pure/Hint/Hint";

export const HintDocument = () => {
	const { t } = useTranslation();
	return <Hint id="DocumentExplorerHint" title={t("documentExplorerHintTitle")} subtitle={t("documentExplorerHintSubtitle")} image={SW} />;
};

export const HintHistory = () => {
	const { t } = useTranslation();
	return <Hint id="HistoryExplorerHint" title={t("historyHintTitle")} subtitle={t("historyHintSubtitle")} image={SM} />;
};
