import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SuggestionBoxIcon } from "../../../assets/icons/Icons";
import { useLanguage } from "../../../state/LanguageContext";
import styles from "./SuggestionBox.module.scss";

interface Box {
	translations: string[];
	groupIndex: number;
}

type SuggestionBoxProps = {
	suggestions: string[];
	index: number;
	onClicked: (value: string) => void;
};

type SuggestionsBoxProps = {
	onClicked: (value: string) => void;
};

function shuffle<T>(array: T[], times = 1): T[] {
	for (let t = 0; t < times; t++) {
		array.sort(() => Math.random() - 0.5);
	}
	return array;
}

const SuggestionBox = ({ index, suggestions, onClicked }: SuggestionBoxProps) => {
	// Choose a random item from suggestions array only once
	const [suggestion, setS] = useState("");

	//state for hover
	const [hover, setHover] = useState(false);

	useEffect(() => {
		setS(suggestions[Math.floor(Math.random() * suggestions.length)]);
	}, [suggestions]);

	return (
		<div
			className={styles.item}
			onClick={() => {
				onClicked(suggestion);
			}}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
			onKeyPress={() => {}}
		>
			<div className={styles.icon}>{SuggestionBoxIcon(index, hover)}</div>
			<div className={styles.content}>{suggestion}</div>
		</div>
	);
};

const getBoxes = (prefix: string, t: (key: string) => string): Box[] => {
	const result: Box[] = [];
	let n = 1; // Start with the first group index

	// Loop through groups until a missing group is found
	// eslint-disable-next-line no-constant-condition
	while (true) {
		const group: string[] = [];
		let m = 1; // Start with the first item index for each group
		let groupHasItems = false;

		// Loop through items in the current group until a missing item is found
		// eslint-disable-next-line no-constant-condition
		while (true) {
			const key = `${prefix}${n}_${m}`;
			const translation = t(key);

			// Check if the translation for the key exists
			if (!translation || key === translation) break;

			group.push(translation);
			groupHasItems = true;
			m++;
		}

		// If no items were added to the group, break the outer loop
		if (!groupHasItems) break;

		result.push({ translations: group, groupIndex: n });
		n++;
	}

	return result;
};

export const SuggestionsBox = ({ onClicked }: SuggestionsBoxProps) => {
	const { t } = useTranslation();

	const [boxes, setBoxes] = useState<Box[]>([]);

	const {
		language: { code },
	} = useLanguage();

	useEffect(() => {
		//console.log("language", code);
		const boxes_ = getBoxes("sb", t);
		setBoxes(shuffle(boxes_, 5));
	}, [code]);

	return (
		<div className={styles.container}>
			{boxes.map((box, index) => (
				<SuggestionBox key={index} index={box.groupIndex} suggestions={box.translations} onClicked={onClicked} />
			))}
		</div>
	);
};
