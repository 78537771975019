import Cookies from "js-cookie";
import { cloneDeep } from "lodash";

import type { BoxType } from "../state/AppContext";

interface CookieItem<T = unknown> {
	get: () => T | null;
	set: (value: T, options?: Cookies.CookieAttributes) => void;
	remove: () => void;
	hasValue: (value?: T) => boolean;
	isUndefinedOrNull: () => boolean;
	update: (data: Partial<T> | T, options?: Cookies.CookieAttributes) => void;
}

const cookieHandler = <T = unknown,>(key: string): CookieItem<T> => {
	const get = (): T | null => {
		const value = Cookies.get(key);
		return value ? JSON.parse(value) : null;
	};

	const set = (value: T, options?: Cookies.CookieAttributes): void => {
		Cookies.set(key, JSON.stringify(value), options);
	};

	const remove = (): void => {
		Cookies.remove(key);
	};

	const isUndefinedOrNull = (): boolean => {
		const value = get();
		return value === null || value === undefined;
	};

	const hasValue = (value?: T): boolean => {
		if (value === undefined) {
			return !isUndefinedOrNull();
		}
		return JSON.stringify(get()) === JSON.stringify(value);
	};

	const update = (data: Partial<T> | T, options?: Cookies.CookieAttributes): void => {
		const currentValue = get();

		if (typeof currentValue === "object" && currentValue !== null && typeof data === "object" && data !== null) {
			// Perform deep merge for objects using cloneDeep
			const updatedValue = { ...cloneDeep(currentValue), ...data };
			set(updatedValue as T, options);
		} else if (typeof currentValue !== typeof data) {
			console.warn(`Type mismatch: Cannot update ${typeof currentValue} with ${typeof data}`);
		} else {
			// Overwrite for primitive types
			set(data as T, options);
		}
	};

	return { get, set, remove, hasValue, isUndefinedOrNull, update };
};

// Example usage:
export const cookieHelper = {
	isCookiesApproved: cookieHandler<boolean>("william_isCookiesApproved"),
	box: cookieHandler<BoxType>("william_box"),
	chat: cookieHandler<string | null>("william_chat"),
};
