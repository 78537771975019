/* eslint-disable react/display-name */
import clsx from "clsx";
import type React from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { FancyBall } from "../../../assets/gfx/images";
import { PaperIcon } from "../../../assets/icons/Icons";
import { Extra } from "../../../components/organisms/Chat/Extra/Extra";
import { AppInnerStates, useAppContext } from "../../../state/AppContext";
import type { ChatMessageDto, RoleType } from "../../../state/ChatContext";
import { isTextPartHTML, isTextPartReference } from "../../../state/ChatContext";
import { AnimatedCursor } from "../_Pure/AnimatedCursor/AnimatedCursor";
import { HtmlDisplay } from "../_Pure/HtmlContent";
import { SupText } from "../_Pure/SupText/SupText";
import { Tooltip, TooltipContentContainer, TooltipTrigger } from "../Tooltip/Tooltip";
import { TooltipContent } from "../Tooltip/TooltipContent/TooltipContent";
import styles from "./Message.module.scss";

export type MessageProps = {
	message: ChatMessageDto;
	children?: React.ReactNode;
	isLoading?: boolean;
	withFancyBall?: boolean;
	origin: RoleType;
};

export const Message = memo(({ message, origin, isLoading, withFancyBall, children }: MessageProps) => {
	const { t } = useTranslation();
	const { dispatch, state } = useAppContext();

	const isBot = origin === "assistant" || origin === "assistant-NoError";

	const body = message.parts.map((part) => {
		if (isTextPartHTML(part)) {
			return (
				<>
					<div key={part.Key} className={styles.htmlContainer}>
						<PaperIcon className={styles.htmlIcon} height={22} />
						<HtmlDisplay htmlContent={part.Text?.toString() ?? ""} className={styles.html} />
					</div>
					<Extra key={`Extra-html-${message.id}`} message={part.Text?.toString() ?? ""} isHtml={true} />
				</>
			);
		}

		if (isTextPartReference(part)) {
			return (
				<Tooltip key={part.Key}>
					<TooltipTrigger>
						<SupText
							text={part.Index?.toString()}
							onClick={() => {
								dispatch({ type: AppInnerStates.SET_ACTS, payload: { msg: message.acts } });
								dispatch({
									type: AppInnerStates.SET_CURRENT_ACT,
									payload: { actGuid: part.Guid },
								});
								if (state.currentBox !== "ActsBrowser") {
									dispatch({ type: AppInnerStates.SET_BOX, payload: { boxType: "ActsBrowser" } });
								}
							}}
						/>
					</TooltipTrigger>
					<TooltipContentContainer>
						<TooltipContent title={part.Title} subtitle={part.Brief} />
					</TooltipContentContainer>
				</Tooltip>
			);
		}

		return <span key={part.Key}>{part.Text}</span>;
	});

	return (
		<div className={styles.container}>
			{isBot && (
				<div className={styles.messageHeader}>
					{withFancyBall && <img alt={t("fancyBall")} src={FancyBall} className={styles.fancyBall} title={t("fancyBall")} />}

					{/* <p className={clsx([styles.date, withFancyBall && styles.translated])}>{dayjs(message).format("HH:mm")}</p> */}
				</div>
			)}
			<div
				className={clsx([
					styles.content,
					state.currentBox !== "None" ? styles.contentW : "",
					isBot ? styles.botMessage : styles.userMessage,
					isLoading && styles.fixedWidth,
				])}
			>
				{!isLoading && body}
				{isLoading && <AnimatedCursor />}
				{!isLoading && children}
			</div>
		</div>
	);
});
